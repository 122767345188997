body {
    top: 0 !important;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
}

#root {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
}



#btr {
    display: flex;
    height: 2.85em;
    overflow: hidden !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: 'center';


}

#btr .goog-te-combo {
    padding: 0.7em;
    cursor: pointer;
    border: 1px solid #AF6E04;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    outline: none;
    width: 6em;
    background-color: white;
    overflow: hidden !important;
}

@media screen and (max-width:315px) {
    #btr .goog-te-combo {
        padding: 0.5em;
        width: 5em;
        overflow: hidden !important;
    }
}




.rs-panel-group.rs-panel-group-accordion {
    border-radius: 0 !important;
}



/* strock text */
.strockText {
    -webkit-text-stroke: 2px #AF6E04;
    color: firebrick;

}

.stroke-text {
    color: #AF6E04;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}

.stroketext1 {
    color: #AF6E04;
    text-shadow: 1px -1px 0 white, 1px -1px 0 white, 1px -1px 0 white, 1px -1px 0 white;
}

.stroketext12 {
    color: white;
    text-shadow: 1px -1px 0 #AF6E04, 1px -1px 0 yellow, 1px -1px 0 blue, 1px -1px 0 red;
}

@media screen and (max-width:310px) {

    .stroke-text {
        color: #AF6E04;
        text-shadow: -0.5px -0.5px 0 white, 0.5px -0.5px 0 white, -0.5px 0.5px 0 white, 0.5px 0.5px 0 white;
    }

    .stroketext1 {
        color: #AF6E04;
        text-shadow: 0.5px -0.5px 0 white, 0.5px -0.5px 0 white, 0.5px -0.5px 0 white, 0.5px -0.5px 0 white;
    }

    .stroketext12 {
        color: white;
        text-shadow: 0.5px -0.5px 0 #AF6E04, 0.5px -0.5px 0 yellow, 0.5px -0.5px 0 blue, 0.5px -0.5px 0 red;
    }



}

/* NavBars de google translate */
.skiptranslate select {
    background-color: transparent !important;
}

.skiptranslate .VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}






/* .VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc:=>  pour le spinner lors de la translation qui affiche*/
.VIpgJd-yAWNEb-hvhgNd {
    display: none;
}


.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
    display: none;
}



.animation {
    animation: scaleTransform 3s infinite;
}

@keyframes scaleTransform {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.01);
    }

    100% {
        transform: scale(1);
    }
}





/* pdf */
.pffAffiche {
    background-color: rgba(128, 128, 128, 0.541);
    gap: 5px;
}

.pdf-document {
    display: flex;
    justify-content: center;
    padding: 20px;
    gap: 5px;
    margin: 10px;
    background-color: rgba(128, 128, 128, 0.541);
}

.react-pdf__Page__canvas {
    margin: 20px !important;
    width: 90% !important;
    height: 90% !important;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
    display: none !important;
}


/* pour le spinner des donnee  */


/* pour le spinner de  des medina ABAHS */
.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50px;
    animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@keyframes lds-default {

    0%,
    20%,
    80%,
    100% {
        transform: scale(0.2);
        background-color: #AF6E04;
    }

    50% {
        transform: scale(1.5);
        background-color: #AF6E04;
    }
}